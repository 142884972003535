<template>
  <div>
    <div
    data-aos="fade-up"
      class="tw-bg-white tw-rounded-md tw-p-4 tw-w-full tw-shadow-sm tw-flex tw-flex-col tw-justify-between"
      v-for="item in plans"
      :key="item?.id"
    >
      <div>
        <h4 class="font-weight-bold mb-2" style="color: var(--main-color)">
          {{ item?.name }}
        </h4>
        <h5 class=""><small>Starting from</small> &#8358;{{ Number(item?.price).toLocaleString() }}</h5>
      </div>

      <p class="my-3 text-secondary small">
        {{ item?.description }}
      </p>

      <div class="tw-text-center">
        <router-link :to="'/' + splitName(item.name)"
          ><button
            class="tw-bg-primary tw-w-full tw-text-sm tw-text-white tw-py-2 tw-rounded-md"
          >
            View More
          </button></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    plans: {
      type: Array,
    },
  },

  data() {
    return {
      //   trimmedPlans: [],
    };
  },

  methods: {
    splitName(item) {
      let link = String(item).split(" ").shift();
      return link.toLowerCase();
    },
  },

  //   watch: {
  //     plans: {
  //       handler(val) {
  //         console.log(val);
  //         if (val) {
  //           this.trimmedPlans = val.split(0, 4);
  //         }
  //       },
  //       immediate: true,
  //     },
  //   },

  computed: {},
};
</script>

<style></style>
