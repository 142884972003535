<template>
  <div class="tw-container tw-mx-auto md:tw-px-6 lg:tw-px-6 tw-px-0">
    <div
      class="tw-flex lg:tw-flex-row md:tw-flex-row tw-flex-col tw-items-center lg:tw-space-x-4 md:tw-space-x-4 tw-space-y-4 tw-py-8"
    >
      <div class="tw-w-full tw-flex tw-justify-center">
        <img
          src="@/assets/img/app-mockup.png"
          class="floater"
          width="50%"
          alt=""
        />
      </div>
      <div class="tw-w-full">
        <h1
          class="tw-text-6xl tw-text-center tw-font-semibold tw-text-gray1"
          style="font-family: var(--header-font)"
        >
          <h1
            class="tw-text-3xl tw-text-center tw-font-medium tw-text-gray1 animate__animated animate__fadeInUp animate__slow"
            style="font-family: var(--header-font)"
          >
            VEO
            <span
              class="tw-text-primary"
              style="font-family: var(--header-font)"
              >HEALTHCARE </span
            >PROVIDERS
          </h1>
        </h1>
        <p
          class="tw-text-center tw-text-sm animate__animated animate__fadeInUp animate__slow animate__delay-1s"
        >
          We use technology to solve major issues faced by the conventional
          HMOs. To get started, Download our mobile apps on
        </p>
        <div
          class="tw-mt-4 tw-flex lg:tw-flex-row md:tw-flex-row tw-flex-col lg:tw-space-x-2 md:tw-space-x-2 tw-space-x-0 lg:tw-space-y-0 md:tw-space-y-0 tw-space-y-2 tw-justify-center tw-items-center animate__animated animate__fadeInUp animate__slow animate__delay-2s"
        >
        <a href="https://play.google.com/store/apps/details?id=com.veohmo.veo_hmo&pli=1" target="_blank">
            <img
            src="@/assets/img/andriod.png"
            alt=""
            class="tw-rounded-lg tw-w-30 tw-h-10"
          />
        </a>

        <a target="_blank" href="https://apps.apple.com/us/app/veo-hmo/id6448048265">
            <img
            src="@/assets/img/ios.png"
            class="tw-rounded-lg tw-w-30 tw-h-10"
            alt=""
          />
        </a>
          
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
