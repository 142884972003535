<template>
  <div class="">
    <page-header :isHome="true">
      <template>
        <hero-section />
        <!-- <search-field /> -->
      </template>
    </page-header>

    <download-image />

    <!-- Our Packages  -->
    <section class="tw-bg-accent">
      <div
        class="tw-container tw-mx-auto md:tw-px-6 lg:tw-px-6 tw-px-0 tw-py-12"
      >
        <h1
          class="tw-text-3xl tw-text-center tw-font-medium tw-text-gray1"
          style="font-family: var(--header-font)"
        >
          OUR
          <span class="tw-text-primary" style="font-family: var(--header-font)"
            >PACKAGES
          </span>
        </h1>
        <div class="tw-mx-auto lg:tw-w-6/12 md:tw-w-6/12 tw-mt-2">
          <p class="tw-text-center tw-text-sm tw-mt-3">
            We have crafted great plans for everyone, no matter your class, your
            association or your taste, we got you covered. The Formal,
            semi-formal and informal sections of our economy is not left out as
            well.
          </p>
        </div>
        <div class="tw-pt-8">
          <div class="tw-text-right tw-mb-3">
            <router-link to="/pricing" class="tw-text-sm tw-text-primary"
              >View All >>>
            </router-link>
          </div>
          <pricing-card
            class="tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-2 sm:tw-grid-cols-1 tw-gap-4"
            :plans="plans"
          />
        </div>
      </div>
    </section>

    <!-- Patient Centered -->
    <section>
      <div
        class="tw-container tw-mx-auto md:tw-px-6 lg:tw-px-6 tw-px-0 tw-py-12"
      >
        <h1
          class="tw-text-3xl tw-text-center tw-font-medium tw-text-gray1"
          style="font-family: var(--header-font)"
        >
          WHO
          <span class="tw-text-primary" style="font-family: var(--header-font)"
            >WE
          </span>
          ARE
        </h1>
        <div
          class="tw-py-8 tw-grid lg:tw-grid-cols-2 md:tw-grid-cols-2 sm:tw-grid-cols-1 tw-gap-8 tw-items-center"
        >
          <div>
            <h1
              class="tw-text-xl tw-text-primary tw-font-medium"
              style="font-family: var(--header-font)"
            >
              Patient Centered
            </h1>
            <p class="tw-text-sm tw-my-3">
              At Veo Hmo, we are patient-centered. placing the needs and the
              welbeing of our enrollee at the heart of everything we do.
              ensuring easy access to good healthcare and prompt response to
              their querry's and demands.
            </p>
            <div
              class="tw-mt-3 tw-flex lg:tw-flex-row md:tw-flex-row tw-flex-col lg:tw-space-x-2 md:tw-space-x-2 tw-space-x-0 lg:tw-space-y-0 md:tw-space-y-0 tw-space-y-2 tw-items-center"
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.veohmo.veo_hmo&pli=1"
                target="_blank"
              >
                <img
                  src="@/assets/img/andriod.png"
                  alt=""
                  class="tw-rounded-lg tw-w-30 tw-h-10"
                />
              </a>

              <a
                target="_blank"
                href="https://apps.apple.com/us/app/veo-hmo/id6448048265"
              >
                <img
                  src="@/assets/img/ios.png"
                  class="tw-rounded-lg tw-w-30 tw-h-10"
                  alt=""
                />
              </a>
            </div>
          </div>
          <img
            src="@/assets/img/image1.jpg"
            data-aos="fade-left"
            class="tw-rounded-lg tw-w-full"
            alt=""
          />
        </div>

        <div
          class="tw-py-8 tw-grid lg:tw-grid-cols-2 md:tw-grid-cols-2 sm:tw-grid-cols-1 tw-gap-8 tw-items-center"
        >
          <img
            src="@/assets/img/image2.jpg"
            data-aos="fade-up"
            alt=""
            class="tw-rounded-lg tw-w-full"
          />
          <div>
            <h1
              class="tw-text-xl tw-text-primary tw-font-medium"
              style="font-family: var(--header-font)"
            >
              Treatment Packages
            </h1>
            <p class="tw-text-sm tw-my-3">
              With us, you have access to the best hospitals and best doctors
              around where you live or work. Our plan comes with Investigations
              and Treatment packages.
            </p>
            <div
              class="tw-mt-3 tw-flex lg:tw-flex-row md:tw-flex-row tw-flex-col lg:tw-space-x-2 md:tw-space-x-2 tw-space-x-0 lg:tw-space-y-0 md:tw-space-y-0 tw-space-y-2 tw-items-center"
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.veohmo.veo_hmo&pli=1"
                target="_blank"
              >
                <img
                  src="@/assets/img/andriod.png"
                  alt=""
                  class="tw-rounded-lg tw-w-30 tw-h-10"
                />
              </a>

              <a
                target="_blank"
                href="https://apps.apple.com/us/app/veo-hmo/id6448048265"
              >
                <img
                  src="@/assets/img/ios.png"
                  class="tw-rounded-lg tw-w-30 tw-h-10"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>

        <div
          class="tw-py-8 tw-grid lg:tw-grid-cols-2 md:tw-grid-cols-2 sm:tw-grid-cols-1 tw-gap-8 tw-items-center"
        >
          <div>
            <h1
              class="tw-text-xl tw-text-primary tw-font-medium"
              style="font-family: var(--header-font)"
            >
              Digital Consultation
            </h1>
            <p class="tw-text-sm tw-my-3">
              As an enrollee, you have a digital id card which enables you to
              access care. You dont have to carry card about just open your app
              and show your Digital Id. This id also enables you to access
              digital consultatin at the comfort of your home
            </p>
            <div
              class="tw-mt-3 tw-flex lg:tw-flex-row md:tw-flex-row tw-flex-col lg:tw-space-x-2 md:tw-space-x-2 tw-space-x-0 lg:tw-space-y-0 md:tw-space-y-0 tw-space-y-2 tw-items-center"
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.veohmo.veo_hmo&pli=1"
                target="_blank"
              >
                <img
                  src="@/assets/img/andriod.png"
                  alt=""
                  class="tw-rounded-lg tw-w-30 tw-h-10"
                />
              </a>

              <a
                target="_blank"
                href="https://apps.apple.com/us/app/veo-hmo/id6448048265"
              >
                <img
                  src="@/assets/img/ios.png"
                  class="tw-rounded-lg tw-w-30 tw-h-10"
                  alt=""
                />
              </a>
            </div>
          </div>
          <img
            src="@/assets/img/image3.jpg"
            data-aos="fade-right"
            alt=""
            class="tw-rounded-lg tw-w-full"
          />
        </div>
      </div>
    </section>

    <!-- How it Works -->
    <section class="tw-bg-accent">
      <div
        class="tw-container tw-mx-auto md:tw-px-6 lg:tw-px-6 tw-px-0 tw-py-12"
      >
        <h1
          class="tw-text-3xl tw-text-center tw-font-medium tw-text-gray1"
          style="font-family: var(--header-font)"
        >
          HOW IT
          <span class="tw-text-primary" style="font-family: var(--header-font)"
            >WORKS
          </span>
        </h1>
        <div
          class="tw-py-8 tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-3 sm:tw-grid-cols-1 tw-gap-4 tw-items-center"
        >
          <div>
            <div class="tw-bg-green-50 tw-p-4 tw-rounded-lg">
              <h1
                class="tw-text-xl tw-text-center tw-text-primary tw-font-medium"
                style="font-family: var(--header-font)"
              >
                Download App
              </h1>
              <p class="tw-text-sm tw-text-center">
                Download the mobile app from google playstore or ios store and register with your 
                personal details on the app
              </p>
            </div>

            <div class="tw-bg-lime-50 tw-p-4 tw-rounded-lg tw-my-4">
              <h1
                class="tw-text-xl tw-text-center tw-text-primary tw-font-medium"
                style="font-family: var(--header-font)"
              >
               Alternatively
              </h1>
              <!-- <p class="tw-text-sm tw-text-center">
                Register with your personal details
              </p> -->
              <span class="tw-text-semibold tw-text-lg">Register Online <a href="https://app.veohmo.com/register" class="tw-underline tw-text-primary" target="_blank">Here</a></span>
            </div>
          </div>
          <div class="tw-w-full tw-flex tw-justify-center">
            <img
              src="@/assets/img/app-mockup.png"
              class="floater"
              width="80%"
              alt=""
            />
          </div>
          <div>
            <div class="tw-bg-emerald-50 tw-p-4 tw-rounded-lg tw-my-4">
              <h1
                class="tw-text-xl tw-text-primary tw-text-center tw-font-medium"
                style="font-family: var(--header-font)"
              >
                Purchase Plan
              </h1>
              <p class="tw-text-sm tw-text-center">
                Purchase a health plan and choose a desired provider in your
                locality
              </p>
            </div>

            <div class="tw-bg-teal-50 tw-p-4 tw-rounded-lg">
              <h1
                class="tw-text-xl tw-text-center tw-text-primary tw-font-medium"
                style="font-family: var(--header-font)"
              >
                Access Healthcare
              </h1>
              <p class="tw-text-sm tw-text-center">
                Access care at the chosen provider whenever you are sick using
                your digital ID found in your mobile app
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
          class="tw-mt-5 tw-flex lg:tw-flex-row md:tw-flex-row tw-flex-col lg:tw-space-x-2 md:tw-space-x-2 tw-space-x-0 lg:tw-space-y-0 md:tw-space-y-0 tw-space-y-2 tw-justify-center tw-items-center"
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.veohmo.veo_hmo&pli=1"
            target="_blank"
          >
            <img
              src="@/assets/img/andriod.png"
              alt=""
              class="tw-rounded-lg tw-w-30 tw-h-10"
            />
          </a>

          <a
            target="_blank"
            href="https://apps.apple.com/us/app/veo-hmo/id6448048265"
          >
            <img
              src="@/assets/img/ios.png"
              class="tw-rounded-lg tw-w-30 tw-h-10"
              alt=""
            />
          </a>
          </div>
          <div class="text-center tw-flex tw-mt-3 tw-flex-col">
            <span class="tw-font-semibold">OR</span>
            <span class="tw-text-semibold tw-text-lg">Register Online <a href="https://app.veohmo.com/register" class="tw-underline tw-text-primary" target="_blank">Here</a></span>
          </div>
        </div>
        
      </div>
    </section>

    <!-- Frequently Asked Questions  -->
    <section>
      <div
        class="tw-container tw-mx-auto md:tw-px-6 lg:tw-px-6 tw-px-0 tw-py-12"
      >
        <h1
          class="tw-text-3xl tw-text-center tw-font-medium tw-text-gray1"
          style="font-family: var(--header-font)"
        >
          FREQUENTLY
          <span class="tw-text-primary" style="font-family: var(--header-font)"
            >ASKED
          </span>
          QUESTIONS
        </h1>
        <faq-s class="tw-mt-6" />
      </div>
    </section>
  </div>
</template>

<script>
import DownloadImage from "@/components/home/DownloadImage.vue";
import PricingCard from "@/components/PricingCard.vue";
import PageHeader from "@/components/Layout/PageHeader.vue";
import HeroSection from "@/components/home/HeroSection.vue";
// import SearchField from "@/components/home/SearchField.vue";
import FaqS from "@/components/home/FAQs.vue";
export default {
  components: {
    DownloadImage,
    PricingCard,
    PageHeader,
    HeroSection,
    // SearchField,
    FaqS,
  },

  data() {
    return {
      plans: [],
    };
  },

  methods: {
    getPlans() {
      this.$axios.get(`${this.baseUrl}plan/all`).then((res) => {
        var resPayload = res.data.data;
        this.plans = resPayload.slice(0, 4);
      });
    },
  },

  beforeMount() {
    this.getPlans();
  },
};
</script>

<style></style>
