<template>
  <div class="tw-container tw-mx-auto md:tw-px-6 lg:tw-px-6 tw-px-0">
    <div class="">
      <div>
        <h4
          class="tw-text-md tw-text-center tw-tracking-wide tw-uppercase tw-text-gray2 tw-font-semibold tw-mt-2 animate__animated animate__fadeInUp"
        >
          Join the VEO Healthcare movement
        </h4>
        <h1
          class="lg:tw-text-6xl md:tw-text-5xl tw-text-4xl tw-text-center tw-font-normal tw-text-gray1 animate__animated animate__fadeInUp"
          style="font-family: var(--header-font)"
          v-text="hero.title"
        ></h1>
        <div
          class="tw-mx-auto lg:tw-w-6/12 md:tw-w-6/12 tw-mt-2 animate__animated animate__fadeInUp"
        >
          <p
            class="tw-text-xs tw-text-center tw-text-gray2 tw-font-medium tw-tracking-widest"
            v-text="hero.description"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animated: true,
      heroes: [
        {
          title:
            "Welcome to Veo HMO where we empower wellness through patient-centered care.",
          description:
            "Our healthcare plans cover everyone; from market women to CEOs and from one man business to multinational corporations. Your path to wellness starts here- choose a plan today and get started.",
        },
        {
          title: "We are technologically driven.",
          description:
            "We have successfully eliminated the bottlenecks of your conventional HMO processes by our cutting edge technologies. Get instant treatment as you approach your provider because your verification is instant.",
        },
        {
          title: "Our support services is 24/7",
          description:
            " As an enrollee be sure that your queries will be resolved as it comes in. And as a provider, your settlement is without bottlenecks and delays.",
        },
      ],
      hero: {
        title: "Unlock your opportunities for a better HealthCare:",
        description:
          "Providing effective healthcare for all regardless of background. Our commitment to affordability, accessibility, integrity and empathy drives our mission.",
      },
    };
  },

  methods: {
    selectRandomHero: function () {
      this.hero = this.heroes[Math.floor(Math.random() * this.heroes.length)];
    },
  },



  created() {
    setInterval(this.selectRandomHero, 5000);
  },

  destroyed() {
    clearInterval(this.selectRandomHero);
  },

  mounted() {
    // this.selectRandomHero();
  },
};
</script>

<style></style>
