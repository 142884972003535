<template>
  <div
    class="tw-flex tw-py-12 tw-cols tw-justify-center tw-items-center"
    :class="{ 'main-hero-section': isHome, 'hero-section-area': !isHome }"
  >
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isHome: {
      default: false,
      type: Boolean,
    },
  },
  components: {},
  data() {
    return {};
  },
};
</script>

<style>
.hero-section-area {
  background: url("@/assets/img/bggg.png");
  background-size: cover;
  background-position: center;
}

.main-hero-section {
  background: url("@/assets/img/bg.gif");
  background-size: cover;
  background-position: center;
  min-height: 500px;
}
</style>
