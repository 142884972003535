<template>
  <div>
    <el-collapse v-model="activeNames">
      <el-collapse-item v-for="item in faqs" :key="item?.id" :title="item?.header" :name="item?.id" >
        <div>
          {{ item?.description }}
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ["1"],
      faqs: [
        {
            id: 1, 
            header: "What is a Health Maintenance Organization (HMO)?",
            description: "A private or public incorporated company registered by the NHIS solely to manage the provision of health care services and they are considered important to maintaining sound health",
        },
        {
            id: 2, 
            header: "Who is a Healthcare Provider (HCP)?",
            description: "An individual or institution that provides medical services (e.g. a physician, hospital, and laboratory)",
        },
        {
            id: 3, 
            header: "Who is a Primary Healthcare Provider?",
            description: "A healthcare professional responsible for providing a wide variety of basic healthcare services. This is usually the entry point into the healthcare system. The PCP has an on-going relationship with the individual and knows the patient's medical history; and therefore, is responsible for the coordination of the care of the enrolee",
        },
        {
            id: 4, 
            header: "Who is a Beneficiary?",
            description: "A person who has enrolled (or have been enrolled) with NHIS and who by being up to date with payment of Premium (or having been paid for) is entitled to cover by NHIS.",
        },
        {
            id: 5, 
            header: "Who is an Enrolee?",
            description: "This is same as a beneficiary",
        },
        {
            id: 6, 
            header: "What is next after form has been filled and premium paid for?",
            description: "The form will be duly processed, which includes the issuance of a policy number and the medical scheme I.D card. Upon issuance of your I.D card you can go to the Primary Care Provider indicated on your form to receive care when the need arises",
        },
        {
            id: 7, 
            header: "Can I still keep my Doctor if the scheme kicks off",
            description: "Only providers accredited for this scheme can be used. You are expected to choose from this list of providers. It is possible your current doctor's hospital facility might be on our network already, in which case you should be able to choose him/her",
        },
        {
            id: 8, 
            header: "What do I do when I pay but cannot receive treatment when I visit my healthcare provider?",
            description: "Either you or the hospital should immediately contact us to verify your payment. When this is done, you should be able to receive treatment.",
        },
        {
            id: 9, 
            header: "Can I change from one healthcare provider to another?",
            description: "Yes, changes can be made under the following circumstances - Transfer to another location, city or state - Dissatisfaction with quality of care delivered by your chosen provider. - Change of enrolee's place of residence. Visit the Veohealth office or any NHIS office in your location and pick up the change healthcare provider form, fill it and attach a passport and submit",
        },
        {
            id: 10, 
            header: "What to do when a private sector enrolee I.D is misplaced?",
            description: "You will be required to formally apply in writing to Veohealth for a replacement of I.D. card, supported with a sworn affidavit. Replacement of lost I.D. card will be at a minimal cost to the enrolee.",
        },
        {
            id: 11, 
            header: "What are the benefits of this scheme?",
            description: "Organization - Better financial void of the setback of medical allowance to staff, retainer hospital and in house clinics. - Improve workforce, which leads to high productivity. - Prepaid premium makes health care budgeting easy and effective.",
        },
        {
            id: 12, 
            header: "What does Co-payment mean?",
            description: "It is the payment made by the enrolee to the accredited pharmacy provider at the point of service.",
        },
        {
            id: 13, 
            header: "What are Exclusions?",
            description: "Exclusions are conditions that are excluded from the benefits package of the NHIS",
        },

      ]
    };
  },
};
</script>

<style>
.el-collapse-item__header {
    height: max-content !important;
    line-height: unset !important;
  padding: 15px;
  font-weight: 600;
}

.el-collapse-item__content {
    padding: 0 15px 15px 15px;
}
</style>
