import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/IndexPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      layout: "HomeLayout",
    },
  },
  {
    path: "/about-us",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      layout: "HomeLayout",
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/ResetPassword.vue"),
    meta: {
      layout: "AppAuthLayout",
    },
  },
  {
    path: "/find-a-provider",
    name: "find-a-provider",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FindProvider.vue"),
    meta: {
      layout: "HomeLayout",
    },
  },
  {
    path: "/pricing",
    name: "pricing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pricingPage.vue"),
    meta: {
      layout: "HomeLayout",
    },
  },
  {
    path: "/frequently-asked-questions",
    name: "faqs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FAQView.vue"),
    meta: {
      layout: "HomeLayout",
    },
  },
  {
    path: "/veobronze",
    name: "veo-bronze",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/plans/veoBronze/indexView.vue"
      ),
    meta: {
      layout: "HomeLayout",
    },
  },
  {
    path: "/gplan",
    name: "veo-gplan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/plans/veoGPlan/indexView.vue"
      ),
    meta: {
      layout: "HomeLayout",
    },
  },
  {
    path: "/veosilver",
    name: "veo-silver",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/plans/veoSilver/indexView.vue"
      ),
    meta: {
      layout: "HomeLayout",
    },
  },
  {
    path: "/veogold",
    name: "veo-gold",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/plans/veoGold/indexView.vue"
      ),
    meta: {
      layout: "HomeLayout",
    },
  },
  {
    path: "/veoplatinum",
    name: "veo-platinum",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/plans/veoPlatinum/indexView.vue"
      ),
    meta: {
      layout: "HomeLayout",
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/privacyPolicy.vue"),
    meta: {
      layout: "HomeLayout",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
  routes,
});

export default router;
