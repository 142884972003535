import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'

Vue.prototype.$axios = axios
const baseUrl = 'https://api.veohmo.com/'
Vue.prototype.baseUrl = baseUrl


import '@/assets/css/main.css'
import '@/assets/css/media-query.css'

import AOS from 'aos'
import 'aos/dist/aos.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI)

Vue.config.productionTip = false

// Vue Form Validation``
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);

new Vue({
    router,
    store,
    mounted() {
        AOS.init()
    },
    render: h => h(App)
}).$mount('#app')