import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate';

let baseURL = "https://api.veohmo.com/"
import axios from 'axios'

Vue.use(Vuex)
const getDefaultState = () => {
    return {
        hospitals: [],
        loading: false,
        plans: [],
        planLoading: false,
        hospitalsMeta: {}
    };
};

export default new Vuex.Store({
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        allHospitals: state => {
            return state.hospitals
        },
        allPlans: state => {
            return state.plans
        },
        isLoading: state => {
            return state.loading
        }
    },
    mutations: {
        SET_HOSPITALS: (state, data) => {
            state.hospitals = data.items
            state.hospitalsMeta = data.meta.pagination
        },
        SET_PLANS: (state, data) => {
            state.plans = data
        },
        SET_LOADING_STATUS: (state, data) => {
            state.loading = data
        },
        SET_PLAN_LOADING: (state, data) => {
            state.planLoading = data
        }
    },
    actions: {
        // Get All Hospitals 
        async getHospitals({ commit }, page) {
            commit('SET_LOADING_STATUS', true)
            try {
                let res = await axios.get(baseURL + "hospitals/all?page="+page);
                console.log(res.data.data);
                let hospitals = res.data.data;
                commit('SET_HOSPITALS', hospitals)
            } catch (error) {
                console.log(error);
            } finally {
                commit('SET_LOADING_STATUS', false)
            }
        },

        // Get all Plans 
        async getPlans({ commit }) {
            commit('SET_PLAN_LOADING', true)
            try {
                let res = await axios.get(baseURL + 'plan/all')
                console.log(res.data.data)
                let plans = res.data.data
                commit('SET_PLANS', plans)
            } catch (error) {
                console.log(error)
            } finally {
                commit('SET_PLAN_LOADING', false)
            }
        },
    },
    modules: {}
})